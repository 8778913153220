<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Shortlisted' &&
            assignmentData.status === 'Candidate Accepted'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    You have accepted the assignment offer!
                  </h5>
                  <p class="mb-0">Wait for your Interview, Thanks!</p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Interview' &&
            assignmentData.status === 'Reschedule Requested'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    You have Applied for Interview Reschedule
                  </h5>
                  <p class="mb-0">Wait for your Response, Thanks!</p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Interview' &&
            assignmentData.status === 'Scheduled'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    Thanks for accepting the Interview offer!
                  </h5>
                  <p class="mb-0">Your Interview is Scheduled!</p>
                  <p class="mb-0">
                    On :
                    <strong>{{ assignmentData.interview.start_date }}</strong>
                    At :
                    <strong>{{ assignmentData.interview.start_time }}</strong>

                    <span v-if="assignmentData.interview.end_time">
                      -
                      <strong>{{ assignmentData.interview.end_time }}</strong>
                    </span>
                  </p>
                  <p class="mb-0" v-if="assignmentData.interview.link">
                    Meeting Link :
                    <a href="#" target="_blank" rel="noopener noreferrer">{{
                      assignmentData.interview.link
                    }}</a>
                  </p>
                  <p class="mb-0" v-if="assignmentData.interview.password">
                    Meeting Password :
                    {{ assignmentData.interview.password }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Interview' &&
            assignmentData.status === 'Interview Requested'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    {{ assignmentData.client.name }}
                  </h5>
                  <p class="mb-0">is inviting you for an Interview !</p>
                  <p class="mb-0">
                    On :
                    <strong>{{ assignmentData.interview.start_date }}</strong>
                    At :
                    <strong>{{ assignmentData.interview.start_time }}</strong>

                    <span v-if="assignmentData.interview.end_time">
                      -
                      <strong>{{ assignmentData.interview.end_time }}</strong>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <b-button
                variant="outline-primary"
                class="mr-2"
                @click="handleAcceptInterview"
              >
                Accept
              </b-button>
              <b-button
                v-b-modal.modal-reschedule
                variant="outline-warning"
                class="mr-2"
              >
                Reschedule
              </b-button>
              <b-button
                variant="outline-danger"
                @click="handleDeclineInterview"
              >
                Decline
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            (assignmentData &&
              assignmentData.assignment_status === 'Shortlisted' &&
              assignmentData.status === 'Candidate Declined') ||
            (assignmentData.assignment_status === 'Interview' &&
              assignmentData.status === 'Candidate Declined')
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar size="lg">
                  <feather-icon icon="FileTextIcon" size="16" />
                </b-avatar>
              </div>
              <div>
                <h5
                  class="bold d-inline"
                  v-if="assignmentData.assignment_status === 'Shortlisted'"
                >
                  You have Declined the Invite offer!
                </h5>
                <h5
                  class="bold d-inline"
                  v-if="assignmentData.assignment_status === 'Interview'"
                >
                  You have Declined the Interview offer!
                </h5>
                <p class="mb-0">Thank you for Your time.</p>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Shortlisted' &&
            assignmentData.status === 'Candidate Invited' &&
            assignmentData.invited === 'yes'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar size="lg">
                  <feather-icon icon="FileTextIcon" size="16" />
                </b-avatar>
              </div>
              <div>
                <span>
                  <strong
                    v-if="
                      assignmentData &&
                      assignmentData.client &&
                      assignmentData.client.name
                    "
                    class="my-0"
                  >
                    {{ assignmentData.client.name }}
                  </strong>
                  <p class="mb-0">is inviting you to apply for this project.</p>
                </span>
              </div>
            </div>
            <div class="d-flex">
              <b-button
                v-b-modal.modal-accept
                variant="outline-primary"
                class="mr-2"
              >
                Accept
              </b-button>
              <!-- <b-button
                v-b-modal.modal-reschedule
                variant="outline-warning"
                class="mr-2"
              >
                Reschedule
              </b-button> -->
              <b-button variant="outline-danger" @click="handleDeclineInvite">
                Decline
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Selected' &&
            assignmentData.status === 'Confirmed'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    You are Selected for the assignment offer!
                  </h5>
                  <p class="mb-0">Wait for your Work to be assigned!</p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Working' &&
            assignmentData.status === 'Work in progress'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    Your work is assigned for the assignment offer!
                  </h5>
                  <p class="mb-0">Wait for your Work to be assigned!</p>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <b-button
                @click="handleCompleteWork"
                variant="outline-primary"
                class="mr-2"
              >
                Complete
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Working' &&
            assignmentData.status === 'Payment Requested'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">
                    Your Assigned work is being Evaluated!
                  </h5>
                  <p class="mb-0">Please Wait for your Payment!</p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card
          v-if="
            assignmentData &&
            assignmentData.assignment_status === 'Working' &&
            assignmentData.status === 'Payment Completed'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">
                  <b-avatar size="lg">
                    <feather-icon icon="FileTextIcon" size="16" />
                  </b-avatar>
                </div>
                <div>
                  <h5 class="bold d-inline">Your Payment been Processed!</h5>
                  <p class="mb-0">Thank you for your time!</p>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="10" md="5" lg="5" class="pr-2 mt-0">
        <!-- requirement component -->
        <!-- <Requirements /> -->
        <!-- hourly compensation  -->
        <!-- <HourlyCompensation /> -->
      </b-col>
      <b-col sm="12" md="12" lg="12" class="mt-0">
        <!-- assignment title component -->
        <!-- <AssignmentTitle /> -->
        <AssignmentDetails :assignmentData="assignmentData" />
      </b-col>
    </b-row>
    <b-modal
      id="modal-reschedule"
      centered
      cancel-title="Close"
      title="Reschedule and Questions"
      ok-only
      size="lg"
      ok-title="Reschedule!"
      @ok="handleRescheduleOffer"
    >
      <div class="px-lg-3 px-2">
        <b-row>
          <b-col cols="12" class="my-1">
            <validation-observer ref="addClientForm">
              <b-form-group class="mb-1">
                <label> Select Date </label>
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="rescheduleDate"
                    selected-variant="primary"
                    :state="errors.length > 0 ? false : null"
                    class="w-sm-75"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mb-1">
                <label> Select Start Time </label>
                <validation-provider
                  #default="{ errors }"
                  name="Start Time"
                  rules="required"
                >
                  <b-form-timepicker
                    v-model="rescheduleStartTime"
                    :state="errors.length > 0 ? false : null"
                    class="w-sm-75"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mb-1">
                <label> Select End Time </label>
                <validation-provider
                  #default="{ errors }"
                  name="End Time"
                  rules="required"
                >
                  <b-form-timepicker
                    v-model="rescheduleEndTime"
                    :state="errors.length > 0 ? false : null"
                    class="w-sm-75"
                    locale="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Add Note" class="mb-1">
                <b-form-textarea
                  v-model="rescheduleNote"
                  placeholder="Write here..."
                  rows="4"
                />
              </b-form-group>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="modal-accept"
      centered
      cancel-title="Close"
      title="Vetting Questions"
      ok-only
      size="lg"
      ok-title="Accept!"
      @ok="handleAcceptInvite"
    >
      <div class="px-lg-3 px-2">
        <b-row>
          <b-col cols="12" class="my-1" v-if="assignmentQuestions.length !== 0">
            <div v-for="(education, index) in assignmentQuestions" :key="index">
              <h6 class="mt-1">{{ index + 1 }}. {{ education.question }}</h6>
              <b-form-textarea
                id="jobRes"
                v-model="education.answer"
                placeholder="Write here..."
                rows="2"
              />
            </div>
          </b-col>
          <b-col cols="12" class="my-1" v-else>
            <h6 class="mt-1">Are You Sure!</h6>
            <p class="mb-0">You want to Accept the invite!</p>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BRow,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import errorResponse from "@/libs/axiosError"
// import AnnualSalary from "./AnnualSalary.vue"
// import AssignmentTitle from "./AssignmentTitle.vue"
// import HourlyCompensation from "./HourlyCompensation.vue"
// import Requirements from "./Requirements.vue"
// import TermsAndCondition from "./TermsAndCondition.vue"
import AssignmentDetails from "./AssignmentDetails.vue"

export default {
  components: {
    BRow,
    BCard,
    BAvatar,
    BButton,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    AssignmentDetails,
    BFormTimepicker,
    BFormDatepicker,
    BFormGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isOfferAccepted: false,
      resident: [],
      required,
      email,
      selectCountryCode: "",
      organization: "",
      selectIndustry: [],
      countryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+689", text: "French(+689)" },
      ],
      industryOptions: [{ title: "Option 1" }, { title: "Option 2" }],
      assignmentData: {},
      assignmentQuestions: [],
      rescheduleNote: "",
    }
  },
  computed: {
    rescheduleDate() {
      return this.assignmentData.interview.start_date
    },
    rescheduleStartTime() {
      return this.assignmentData.interview.start_time
    },
    rescheduleEndTime() {
      return this.assignmentData.interview.end_time
    },
  },
  created() {
    this.getAssignmentData()
  },
  methods: {
    handleDeclineInvite() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Decline the offer!.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(`/assignments/candidate_decline_invite`, {
                assignment_id: this.assignmentData.assignment_id,
                candidate_id: this.assignmentData.candidate_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Invite Decline`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Declined the Invite!`,
                  },
                })
                this.getAssignmentData()
              })
              .catch((error) => {
                errorResponse(error, this.$router)
              })
          }
        })
    },
    handleAcceptInvite() {
      axios
        .post(`/assignments/candidate_accept_invite`, {
          assignment_id: this.assignmentData.assignment_id,
          candidate_id: this.assignmentData.candidate_id,
          answers: this.assignmentQuestions,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Invite Accept`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully Accepted the Invite!`,
            },
          })
          this.getAssignmentData()
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    getAssignmentData() {
      axios
        .get(`/candidate_invites/assignment/${this.$route.params.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment Details`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment Details!`,
            },
          })

          this.assignmentData = res.data
          if (res.data.assignment.question) {
            const data = res.data.assignment.question.split(",")
            data.forEach((element) => {
              this.assignmentQuestions.push({
                question: element,
                answer: "",
              })
            })
          }
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    handleDeclineInterview() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Decline the Interview Request!.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post(`/assignments/candidate_decline_interview`, {
                assignment_id: this.assignmentData.assignment_id,
                candidate_id: this.assignmentData.candidate_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Interview Decline`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Declined the Interview!`,
                  },
                })
                this.getAssignmentData()
              })
              .catch((error) => {
                errorResponse(error, this.$router)
              })
          }
        })
    },
    handleAcceptInterview() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Accept the Interview Request!.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post(`/assignments/candidate_accept_interview`, {
                assignment_id: this.assignmentData.assignment_id,
                candidate_id: this.assignmentData.candidate_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Interview Accept`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Accepted the Interview Invite!`,
                  },
                })
                this.getAssignmentData()
              })
              .catch((error) => {
                errorResponse(error, this.$router)
              })
          }
        })
    },
    handleRescheduleOffer() {
      axios
        .post(`/assignments/candidate_reschedule_interview`, {
          assignment_id: this.assignmentData.assignment_id,
          candidate_id: this.assignmentData.candidate_id,
          rescheduleNote: this.rescheduleNote,
          rescheduleDate: this.rescheduleDate,
          rescheduleStartTime: this.rescheduleStartTime,
          rescheduleEndTime: this.rescheduleEndTime,
          interview_id: this.assignmentData.interview_id,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment Accept`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully Accepted the Invite!`,
            },
          })
          this.getAssignmentData()
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    handleCompleteWork() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Complete the offer!.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(`/assignments/candidate_complete_work`, {
                assignment_id: this.assignmentData.assignment_id,
                candidate_id: this.assignmentData.candidate_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Complete Assignment`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Completed the Assignment!`,
                  },
                })
                this.getAssignmentData()
              })
              .catch((error) => {
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>
