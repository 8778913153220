var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Shortlisted' &&
          _vm.assignmentData.status === 'Candidate Accepted'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" You have accepted the assignment offer! ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Wait for your Interview, Thanks!")])])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Interview' &&
          _vm.assignmentData.status === 'Reschedule Requested'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" You have Applied for Interview Reschedule ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Wait for your Response, Thanks!")])])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Interview' &&
          _vm.assignmentData.status === 'Scheduled'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" Thanks for accepting the Interview offer! ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Your Interview is Scheduled!")]),_c('p',{staticClass:"mb-0"},[_vm._v(" On : "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.start_date))]),_vm._v(" At : "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.start_time))]),(_vm.assignmentData.interview.end_time)?_c('span',[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.end_time))])]):_vm._e()]),(_vm.assignmentData.interview.link)?_c('p',{staticClass:"mb-0"},[_vm._v(" Meeting Link : "),_c('a',{attrs:{"href":"#","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.assignmentData.interview.link))])]):_vm._e(),(_vm.assignmentData.interview.password)?_c('p',{staticClass:"mb-0"},[_vm._v(" Meeting Password : "+_vm._s(_vm.assignmentData.interview.password)+" ")]):_vm._e()])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Interview' &&
          _vm.assignmentData.status === 'Interview Requested'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" "+_vm._s(_vm.assignmentData.client.name)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v("is inviting you for an Interview !")]),_c('p',{staticClass:"mb-0"},[_vm._v(" On : "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.start_date))]),_vm._v(" At : "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.start_time))]),(_vm.assignmentData.interview.end_time)?_c('span',[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(_vm.assignmentData.interview.end_time))])]):_vm._e()])])])]),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.handleAcceptInterview}},[_vm._v(" Accept ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-reschedule",modifiers:{"modal-reschedule":true}}],staticClass:"mr-2",attrs:{"variant":"outline-warning"}},[_vm._v(" Reschedule ")]),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDeclineInterview}},[_vm._v(" Decline ")])],1)])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          (_vm.assignmentData &&
            _vm.assignmentData.assignment_status === 'Shortlisted' &&
            _vm.assignmentData.status === 'Candidate Declined') ||
          (_vm.assignmentData.assignment_status === 'Interview' &&
            _vm.assignmentData.status === 'Candidate Declined')
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[(_vm.assignmentData.assignment_status === 'Shortlisted')?_c('h5',{staticClass:"bold d-inline"},[_vm._v(" You have Declined the Invite offer! ")]):_vm._e(),(_vm.assignmentData.assignment_status === 'Interview')?_c('h5',{staticClass:"bold d-inline"},[_vm._v(" You have Declined the Interview offer! ")]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v("Thank you for Your time.")])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Shortlisted' &&
          _vm.assignmentData.status === 'Candidate Invited' &&
          _vm.assignmentData.invited === 'yes'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('span',[(
                    _vm.assignmentData &&
                    _vm.assignmentData.client &&
                    _vm.assignmentData.client.name
                  )?_c('strong',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.assignmentData.client.name)+" ")]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v("is inviting you to apply for this project.")])])])]),_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-accept",modifiers:{"modal-accept":true}}],staticClass:"mr-2",attrs:{"variant":"outline-primary"}},[_vm._v(" Accept ")]),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDeclineInvite}},[_vm._v(" Decline ")])],1)])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Selected' &&
          _vm.assignmentData.status === 'Confirmed'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" You are Selected for the assignment offer! ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Wait for your Work to be assigned!")])])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Working' &&
          _vm.assignmentData.status === 'Work in progress'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" Your work is assigned for the assignment offer! ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Wait for your Work to be assigned!")])])])]),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.handleCompleteWork}},[_vm._v(" Complete ")])],1)])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Working' &&
          _vm.assignmentData.status === 'Payment Requested'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v(" Your Assigned work is being Evaluated! ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Please Wait for your Payment!")])])])])])]):_vm._e()],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          _vm.assignmentData &&
          _vm.assignmentData.assignment_status === 'Working' &&
          _vm.assignmentData.status === 'Payment Completed'
        )?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"lg"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}})],1)],1),_c('div',[_c('h5',{staticClass:"bold d-inline"},[_vm._v("Your Payment been Processed!")]),_c('p',{staticClass:"mb-0"},[_vm._v("Thank you for your time!")])])])])])]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"pr-2 mt-0",attrs:{"sm":"10","md":"5","lg":"5"}}),_c('b-col',{staticClass:"mt-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('AssignmentDetails',{attrs:{"assignmentData":_vm.assignmentData}})],1)],1),_c('b-modal',{attrs:{"id":"modal-reschedule","centered":"","cancel-title":"Close","title":"Reschedule and Questions","ok-only":"","size":"lg","ok-title":"Reschedule!"},on:{"ok":_vm.handleRescheduleOffer}},[_c('div',{staticClass:"px-lg-3 px-2"},[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('validation-observer',{ref:"addClientForm"},[_c('b-form-group',{staticClass:"mb-1"},[_c('label',[_vm._v(" Select Date ")]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"w-sm-75",attrs:{"selected-variant":"primary","state":errors.length > 0 ? false : null},model:{value:(_vm.rescheduleDate),callback:function ($$v) {_vm.rescheduleDate=$$v},expression:"rescheduleDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('label',[_vm._v(" Select Start Time ")]),_c('validation-provider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"w-sm-75",attrs:{"state":errors.length > 0 ? false : null,"locale":"en"},model:{value:(_vm.rescheduleStartTime),callback:function ($$v) {_vm.rescheduleStartTime=$$v},expression:"rescheduleStartTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('label',[_vm._v(" Select End Time ")]),_c('validation-provider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"w-sm-75",attrs:{"state":errors.length > 0 ? false : null,"locale":"en"},model:{value:(_vm.rescheduleEndTime),callback:function ($$v) {_vm.rescheduleEndTime=$$v},expression:"rescheduleEndTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Add Note"}},[_c('b-form-textarea',{attrs:{"placeholder":"Write here...","rows":"4"},model:{value:(_vm.rescheduleNote),callback:function ($$v) {_vm.rescheduleNote=$$v},expression:"rescheduleNote"}})],1)],1)],1)],1)],1)]),_c('b-modal',{attrs:{"id":"modal-accept","centered":"","cancel-title":"Close","title":"Vetting Questions","ok-only":"","size":"lg","ok-title":"Accept!"},on:{"ok":_vm.handleAcceptInvite}},[_c('div',{staticClass:"px-lg-3 px-2"},[_c('b-row',[(_vm.assignmentQuestions.length !== 0)?_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},_vm._l((_vm.assignmentQuestions),function(education,index){return _c('div',{key:index},[_c('h6',{staticClass:"mt-1"},[_vm._v(_vm._s(index + 1)+". "+_vm._s(education.question))]),_c('b-form-textarea',{attrs:{"id":"jobRes","placeholder":"Write here...","rows":"2"},model:{value:(education.answer),callback:function ($$v) {_vm.$set(education, "answer", $$v)},expression:"education.answer"}})],1)}),0):_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('h6',{staticClass:"mt-1"},[_vm._v("Are You Sure!")]),_c('p',{staticClass:"mb-0"},[_vm._v("You want to Accept the invite!")])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }